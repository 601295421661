const handleClickSchedule = () => {
  const phoneNumber = "5547991886551";
  const message =
    "Olá! Eu vim do site e gostaria de saber mais sobre os tratamentos oferecidos.";

  window.open(
    `https://wa.me/${phoneNumber}?text=${encodeURIComponent(message)}`,
    "_blank",
  );
};

export { handleClickSchedule };
