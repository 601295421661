import React from "react";
import "./ComingSoon.scss";
import logo from "../../assets/images/logos/retangular.svg";
import instaIcon from "../../assets/images/icons/instagram.svg";
import whatsappIcon from "../../assets/images/icons/whatsapp.svg";
import { handleClickSchedule } from "Helper";

const ComingSoon: React.FC = () => {
  return (
    <div className="coming-soon-container">
      <div className="content">
        <img src={logo} alt="SPA Energético" className="logo" />
        <h1>Estamos Trabalhando em Algo Novo!</h1>
        <p>Em breve, teremos novidades incríveis para você. Fique atento!</p>
        <p>
          Enquanto isso, sinta-se à vontade para nos seguir nas redes sociais.
        </p>
        <div className="social-links">
          <a
            href="https://www.instagram.com/sodalitaspaenergetico/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={instaIcon} alt="Instagram" className="social-link" />
          </a>
          <img
            src={whatsappIcon}
            alt="Enviar mensagem por WhatsApp"
            className="social-link"
            onClick={handleClickSchedule}
          />
        </div>
        <div className="contact-info">
          <p>
            <a href="mailto:sodalitaspa@gmail.com">sodalitaspa@gmail.com</a>
          </p>
        </div>
      </div>
    </div>
  );
};

export default ComingSoon;
