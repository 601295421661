import { useEffect, useState } from "react";
import AOS from "aos";
import "aos/dist/aos.css";

import Header from "./components/header/Header";
import About from "./components/about/About";
import Contact from "./components/contact/Contact";
import Services from "./components/services/Services";
import Testimonials from "./components/testimonials/Testimonials";
import DefaultConsent from "default-consent/DefaultConsent";
import WhatsAppButton from "./components/floating-whatsapp-button/FloatingWhatsAppButton";
import "./App.scss";
import Services2 from "./components/services/Services2";
import ComingSoon from "./components/coming-soon/ComingSoon";

const App = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000,
      once: false,
    });
  }, []);

  return (
    <div className="app">
      {/* <Header />
      <main className="content">
        <Services2 />
        <About />
        <Services />
        <Testimonials />
        <Contact />
      </main>

      <WhatsAppButton />
      <DefaultConsent /> */}
      <ComingSoon />
    </div>
  );
};

export default App;
