import App from "App";
import CookiesPolicy from "default-consent/CookiesPolicy";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "ScrollToTop";

const AppRoutes = () => (
  <Router>
    {/* <ScrollToTop /> */}
    <Routes>
      <Route path="/" element={<App />} />
      <Route path="/cookies-policy" element={<CookiesPolicy />} />
      {/* <Route path="*" element={<Navigate to="/" replace />} /> */}
    </Routes>
  </Router>
);

export default AppRoutes;
