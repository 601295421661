import "./CookiePolicy.scss";

const CookiePolicy = () => {
  return (
    <div className="cookie-policy">
      <h1>Política de Cookies</h1>

      <h2>O que são cookies?</h2>
      <p>
        Cookies são pequenos arquivos de texto armazenados no seu dispositivo
        quando você visita um site. Eles são amplamente utilizados para fazer os
        sites funcionarem de forma mais eficiente, bem como para fornecer
        informações aos proprietários do site.
      </p>

      <h2>Como usamos os cookies?</h2>
      <p>
        Usamos cookies para melhorar a sua experiência no nosso site. Os cookies
        nos ajudam a:
      </p>
      <ul>
        <li>Entender como os visitantes interagem com nosso site</li>
        <li>Lembrar suas preferências e configurações</li>
        <li>Melhorar a funcionalidade do site</li>
        <li>Fornecer conteúdo e anúncios relevantes</li>
      </ul>

      <h2>Tipos de cookies que utilizamos</h2>

      <h3>Cookies estritamente necessários</h3>
      <p>
        Esses cookies são essenciais para que você possa navegar pelo site e
        usar seus recursos. Sem esses cookies, os serviços que você solicitou
        não podem ser fornecidos.
      </p>

      <h3>Cookies de desempenho</h3>
      <p>
        Esses cookies coletam informações sobre como os visitantes usam o site,
        como quais páginas são mais visitadas e se eles recebem mensagens de
        erro. Essas informações são usadas para melhorar o funcionamento do
        site.
      </p>

      <h3>Cookies de funcionalidade</h3>
      <p>
        Esses cookies permitem que o site se lembre das escolhas que você faz
        (como seu nome de usuário, idioma ou região) e forneça recursos
        aprimorados e mais pessoais.
      </p>

      <h3>Cookies de segmentação/publicidade</h3>
      <p>
        Esses cookies são usados para entregar anúncios mais relevantes para
        você e seus interesses. Eles também são usados para limitar o número de
        vezes que você vê um anúncio e ajudar a medir a eficácia das campanhas
        publicitárias.
      </p>

      <h2>Como controlar cookies</h2>
      <p>
        Você pode controlar e/ou excluir cookies como desejar. Para mais
        informações, visite{" "}
        <a
          href="https://aboutcookies.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          aboutcookies.org
        </a>
        . Você pode excluir todos os cookies que já estão no seu computador e
        configurar a maioria dos navegadores para impedir que eles sejam
        armazenados. No entanto, se você fizer isso, pode ser necessário ajustar
        manualmente algumas preferências toda vez que visitar um site, e alguns
        serviços e funcionalidades podem não funcionar.
      </p>

      <h2>Alterações nesta política</h2>
      <p>
        Podemos atualizar nossa política de cookies de tempos em tempos.
        Recomendamos que você reveja esta política periodicamente para se manter
        informado sobre como estamos protegendo suas informações.
      </p>

      <h2>Contato</h2>
      <p>
        Se você tiver dúvidas sobre nossa política de cookies, entre em contato
        conosco:
      </p>
      <ul>
        <li>
          Email: <a href="mailto:seuemail@exemplo.com">sodalitaspa@gmail.com</a>
        </li>
        <li>Telefone: (47) 9 9188-6551</li>
      </ul>
    </div>
  );
};

export default CookiePolicy;
